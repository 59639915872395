<template>
  <v-container fluid>
    <div class="main">
      <v-row v-if="isLoading">
        <v-col cols="6" lg="3" v-for="item in 4" :key="'cards1_' + item">
          <v-card flat class="rounded-lg">
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6" lg="6" v-for="item in 4" :key="'cards2_' + item">
          <v-card flat class="rounded-lg">
            <v-card-text>
              <v-skeleton-loader type="article"></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div v-else>
        <v-row>
          <v-col cols="12">
            <v-card class="rounded-lg mt-2" flat>
              <v-card-text>
                <div class="d-flex justify-space-between">
                  <v-btn
                    text
                    color="grey"
                    class="rounded-lg"
                    @click="$router.go(-1)"
                  >
                    <v-icon left>mdi-arrow-left</v-icon>
                    Retour
                  </v-btn>

                  <h4>
                    {{ phone }}
                  </h4>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <!-- Par jour -->
          <v-col cols="12" lg="4" md="6">
            <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
            >
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center"
                  color="success"
                  size="55"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-percent</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start align-center mt-4">
                <p class="font-weight-bold text-lg mb-1 mt-2">
                  Consommation <br />Par Jour
                </p>
                <p class="font-weight-black text-lg mb-1 title">
                  {{ CurrencyFormatting(analyse.stats.per_day) }} DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>

          <!-- Par week -->
          <v-col cols="12" lg="4" md="6">
            <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
            >
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center"
                  color="success"
                  size="55"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-percent</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start align-center mt-4">
                <p class="font-weight-bold text-lg mb-1 mt-2">
                  Consommation <br />Par semaine
                </p>
                <p class="font-weight-black text-lg mb-1 title">
                  {{ CurrencyFormatting(analyse.stats.per_week) }} DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>

          <!-- Par mois -->
          <v-col cols="12" lg="4" md="6">
            <v-sheet
              :rounded="'lg'"
              class="text-center d-flex align-center"
              style="height: 100%"
            >
              <v-card-title class="align-center text-center">
                <v-avatar
                  class="elevation-3 text-center"
                  color="success"
                  size="55"
                  style="margin: auto"
                >
                  <v-icon color="white "> mdi-percent</v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="align-start align-center mt-4">
                <p class="font-weight-bold text-lg mb-1 mt-2">
                  Consommation <br />Par mois
                </p>
                <p class="font-weight-black text-lg mb-1 title">
                  {{ analyse.stats.per_month }} DZD
                </p>
              </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-sheet :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="info" size="38">
                  <v-icon color="white"> mdi-ticket-percent </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Consommation par voucher (CA)</span
                >
              </v-card-title>

              <v-card-text class="align-start mt-2">
                <!-- <LineChart :labels="dashboardData.trasaction_evolutions.dates" :data="dashboardData.trasaction_evolutions.amounts" /> -->
                <DoughnutChart
                  :labels="analyse.byDayGraph.labelPrices"
                  :data="analyse.graphMontant"
                  title=" Consommation par voucher"
                  cutout="0"
                />
              </v-card-text>
            </v-sheet>
          </v-col>

          <v-col cols="12" lg="6">
            <v-sheet :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="info" size="38">
                  <v-icon color="white"> mdi-ticket-percent </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Consommation par voucher (Quantite)</span
                >
              </v-card-title>

              <v-card-text class="align-start mt-2">
                <!-- <LineChart :labels="dashboardData.trasaction_evolutions.dates" :data="dashboardData.trasaction_evolutions.amounts" /> -->
                <Bar
                  :labels="analyse.byDayGraph.labelQuantity"
                  :data="analyse.graphQuantity"
                  title=" Consommation par quantité"
                  cutout="0"
                />
              </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="8">
            <v-sheet :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-update </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Consommation par jour</span
                >
              </v-card-title>

              <v-card-text class="align-start mt-2">
                <!-- <LineChart :labels="dashboardData.trasaction_evolutions.dates" :data="dashboardData.trasaction_evolutions.amounts" /> -->
                <!-- <LineChart
                  :labels="analyse.byDayGraph.days"
                  :data="analyse.byDayGraph.totals"
                /> -->

                <Bar
                  :labels="analyse.byDayGraph.days"
                  :data="analyse.byDayGraph.totals"
                  title=" Consommation par jours"
                  :is_money="true"
                />
              </v-card-text>
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="4">
            <v-sheet :rounded="'lg'">
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-percent</v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3"> Vouchers</span>
              </v-card-title>
              <v-card-text class="align-start mt-2">
                <div v-if="analyse.vouchers.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="font-weight-bold text-center">Montant</th>
                          <th class="font-weight-bold text-center">Quantite</th>
                          <th class="font-weight-bold text-right">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in analyse.vouchers" :key="item.id">
                          <td class="font-weight-bold text-center text-no-wrap">
                            {{ CurrencyFormatting(item.amount) }} DZD
                          </td>
                          <td class="font-weight-bold text-center text-no-wrap">
                            {{ item.quantity }}
                          </td>
                          <td
                            class="
                              font-weight-bold
                              text-right
                              pink--text
                              darken-2
                              text-no-wrap
                            "
                          >
                            {{ CurrencyFormatting(item.total) }} DZD
                          </td>
                        </tr>
                        <tr style="border-top: 1px solid #333">
                          <td class="font-weight-bold text-right">
                            <strong>Total :</strong>
                          </td>
                          <td class="font-weight-bold text-center">
                            {{ CurrencyFormatting(analyse.total_quantity) }}
                          </td>
                          <td class="font-weight-bold text-right">
                            {{ CurrencyFormatting(analyse.total_total) }} DZD
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div v-else class="text-center">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </v-card-text>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row v-if="isLoadingTransaction">
          <v-col cols="12">
            <v-skeleton-loader type="table"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-card class="rounded-lg" flat>
              <v-card-title class="align-start d-flex">
                <v-avatar class="elevation-3" color="primary" size="38">
                  <v-icon color="white"> mdi-clock </v-icon>
                </v-avatar>
                <span class="font-weight-bold text-center ml-3">
                  Transactions</span
                >
              </v-card-title>
              <v-card-text>
                <div class="d-none d-lg-block">
                  <div class="d-flex justify-space-between mb-2">
                    <div></div>
                    <div class="d-flex justify-space-between"></div>
                  </div>
                  <v-divider />
                </div>
                <div v-if="isLoading">
                  <v-skeleton-loader type="table"></v-skeleton-loader>
                </div>
                <div v-else>
                  <div v-if="transactions.data.length > 0">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="font-weight-bold text-center">ID</th>
                            <th class="font-weight-bold text-center">Date</th>
                            <th class="font-weight-bold text-center">
                              Montant
                            </th>
                            <th class="font-weight-bold text-center">
                              Supplier
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in transactions.data" :key="item.id">
                            <td class="font-weight-bold text-center">
                              {{ item.voucher.id }}
                            </td>

                            <td class="font-weight-bold text-center">
                              {{ item.created_at }}
                            </td>

                            <td
                              class="
                                font-weight-bold
                                text-center text-no-wrap
                                pink--text
                                darken-2
                              "
                            >
                              {{ CurrencyFormatting(item.voucher.amount) }} DZD
                            </td>

                            <td class="font-weight-bold text-center">
                              {{
                                item.voucher.supplier != null
                                  ? item.voucher.supplier.name
                                  : "-"
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div class="d-flex mt-4">
                      <span class="mt-2 mr-2">Lignes par page :</span>
                      <v-select
                        filled
                        v-model="filter.size"
                        style="width: 20px"
                        dense
                        :items="[10, 20, 30, 40, 50]"
                        @change="onPageChange"
                      ></v-select>
                      <v-spacer />
                      <v-pagination
                        total-visible="6"
                        v-if="transactions.total > 15"
                        circle
                        v-model="filter.page"
                        :length="Math.ceil(transactions.total / filter.size)"
                        @input="onPageChange"
                      ></v-pagination>
                    </div>
                  </div>
                  <div v-else class="text-center">
                    <v-avatar tile size="200">
                      <v-img :src="require('@/assets/database.svg')"></v-img>
                    </v-avatar>
                    <h3 class="text--primary d-block mb-2">OOPS !</h3>
                    <p>Aucun enregistrement correspondant trouvé.</p>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import DoughnutChart from "@/components/charts/DoughnutChart.vue";
//import LineChart from "@/components/charts/LineChart.vue";
import Bar from "@/components/charts/Bar.vue";

export default {
  components: { DoughnutChart, Bar },
  data() {
    return {
      phone: this.$route.params.phone,
      filter: {
        startDate: "",
        endDate: "",
        keyword: "",
        supplier_ids: [],
        size: 10,
        page: 1,
      },
      pagination: {
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    fetchData() {
      this.$store.dispatch("fetchAnalyseOneDriver", {
        phone: this.$route.params.phone,
      });
    },

    fetchTransactions() {
      this.$store.dispatch("fetchDriverTransactions", {
        filter: this.filter,
        phone: this.$route.params.phone,
      });
    },
    onPageChange() {
      this.fetchTransactions();
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
    isLoadingTransaction() {
      return this.$store.getters.isLoadingTransaction;
    },
    analyse() {
      return this.$store.getters.getAnalysesVouchersAccount;
    },
    transactions() {
      return this.$store.getters.getDriverTransactions;
    },
  },
  mounted() {
    this.fetchData();
    this.fetchTransactions();
  },
};
</script>